import { storeToRefs } from 'pinia'
import { IProvider } from '~/types/lobby.type'
import { PAGE } from '~/constants/router'
import { useGameStore } from '~~/store/game'
import { PAGE_URLS, SPORTS_URLS, LOBBY_GAME_URLS } from '~/config/page-url'
export const getGameCenter = () => {
  const { $config, $pinia } = useNuxtApp()
  const storeGame = useGameStore($pinia)
  const { providerGames, providerCasino } = storeToRefs(storeGame)
  return {
    HOME_SPORTS: [
      {
        title: 'C-SPORTS',
        image: '/assets/images/components/desktop/pages/home/csport.webp',
        image_ncc: '/assets/images/components/desktop/pages/home/icon-csport.webp',
        link: SPORTS_URLS.C_SPORTS,
        isFunction: true
      },
      {
        title: 'K-SPORTS',
        image: '/assets/images/components/desktop/pages/home/ksport.webp',
        image_ncc: '/assets/images/components/desktop/pages/home/icon-ksport.webp',
        link: SPORTS_URLS.K_SPORTS,
        isFunction: true
      },
      {
        title: 'P-SPORTS',
        image: '/assets/images/components/desktop/pages/home/psport.webp',
        image_ncc: '/assets/images/components/desktop/pages/home/icon-psport.webp',
        link: SPORTS_URLS.P_SPORTS,
        isFunction: true
      },
      {
        title: 'Bắn cá',
        image: '/assets/images/components/desktop/pages/home/ban-ca.webp',
        link: LOBBY_GAME_URLS.BAN_CA
      },
      {
        title: 'game bài',
        image: '/assets/images/components/desktop/pages/home/game-bai.webp',
        link: LOBBY_GAME_URLS.GAME_BAI
      },
      {
        title: 'Slots',
        image: '/assets/images/components/desktop/pages/home/slots.webp',
        link: LOBBY_GAME_URLS.SLOTS_GAME
      },
      {
        title: 'Nổ hũ',
        image: '/assets/images/components/desktop/pages/home/no-hu.webp',
        link: LOBBY_GAME_URLS.NO_HU
      },
      {
        title: 'LIVE CASINO',
        image: '/assets/images/components/desktop/pages/home/casino.webp',
        link: PAGE.CASINO + '/' + (providerCasino?.value && providerCasino.value.length > 0 ? (providerCasino.value[0] as IProvider)?.alias : 'all')
      },
      {
        title: 'Lô đề',
        image: '/assets/images/components/desktop/pages/home/lo-de.webp',
        link: LOBBY_GAME_URLS.LO_DE
      },
      {
        title: 'Quay số',
        image: '/assets/images/components/desktop/pages/home/keno.webp',
        link: LOBBY_GAME_URLS.QUAY_SO
      },
      {
        title: 'Table Games',
        image: '/assets/images/components/desktop/pages/home/tables-game.webp',
        link: LOBBY_GAME_URLS.TABLE_GAME
      },
      {
        title: 'Siêu hoàn trả',
        image: '/assets/images/components/desktop/pages/home/refund.webp',
        link: PAGE_URLS.PROMOTION
      }
    ],
    HOME_SPORTS_MB: [
      {
        title: 'C-SPORTS',
        image: 'assets/images/components/desktop/pages/home/csport.webp',
        image_mb: '/assets/images/components/desktop/pages/home/csport-mb.webp',
        image_ncc: '/assets/images/components/desktop/pages/home/icon-csport-mb.webp',
        link: SPORTS_URLS.C_SPORTS,
        isFunction: true
      },
      {
        title: 'K-SPORTS',
        image: 'assets/images/components/desktop/pages/home/ksport.webp',
        image_mb: '/assets/images/components/desktop/pages/home/ksport-mb.webp',
        image_ncc: '/assets/images/components/desktop/pages/home/icon-ksport-mb.webp',
        link: SPORTS_URLS.K_SPORTS,
        isFunction: true
      },
      {
        title: 'P-SPORTS',
        image: 'assets/images/components/desktop/pages/home/psport.webp',
        image_mb: '/assets/images/components/desktop/pages/home/psport-mb.webp',
        image_ncc: '/assets/images/components/desktop/pages/home/icon-psport-mb.webp',
        link: SPORTS_URLS.P_SPORTS,
        isFunction: true
      },
      {
        title: 'game bài',
        image: '/assets/images/components/desktop/pages/home/game-bai.webp',
        image_mb: '/assets/images/components/desktop/pages/home/game-bai-mb.webp',
        link: LOBBY_GAME_URLS.GAME_BAI
      },
      {
        title: 'Table Games',
        image: '/assets/images/components/desktop/pages/home/tables-game.webp',
        image_mb: '/assets/images/components/desktop/pages/home/tables-game-mb.webp',
        link: LOBBY_GAME_URLS.TABLE_GAME
      },
      {
        title: 'Quay số',
        image: '/assets/images/components/desktop/pages/home/keno.webp',
        image_mb: '/assets/images/components/desktop/pages/home/keno-mb.webp',
        link: LOBBY_GAME_URLS.QUAY_SO
      },
      {
        title: 'Nổ hũ',
        image: '/assets/images/components/desktop/pages/home/no-hu.webp',
        image_mb: '/assets/images/components/desktop/pages/home/no-hu-mb.webp',
        link: LOBBY_GAME_URLS.NO_HU
      },
      {
        title: 'LIVE CASINO',
        image: '/assets/images/components/desktop/pages/home/casino.webp',
        image_mb: '/assets/images/components/desktop/pages/home/casino-mb.webp',
        link: PAGE.CASINO + '/' + (providerCasino?.value && providerCasino.value.length > 0 ? (providerCasino.value[0] as IProvider)?.alias : 'all')
      },
      {
        title: 'Bắn cá',
        image: '/assets/images/components/desktop/pages/home/ban-ca.webp',
        image_mb: '/assets/images/components/desktop/pages/home/ban-ca-mb.webp',
        link: LOBBY_GAME_URLS.BAN_CA
      },
      {
        title: 'Lô đề',
        image: '/assets/images/components/desktop/pages/home/lo-de.webp',
        image_mb: '/assets/images/components/desktop/pages/home/lo-de-mb.webp',
        link: LOBBY_GAME_URLS.LO_DE
      },
      {
        title: 'Slots',
        image: '/assets/images/components/desktop/pages/home/slots.webp',
        image_mb: '/assets/images/components/desktop/pages/home/slots-mb.webp',
        link: LOBBY_GAME_URLS.SLOTS_GAME
      },
      {
        title: 'Siêu hoàn trả',
        image: '/assets/images/components/desktop/pages/home/refund.webp',
        image_mb: '/assets/images/components/desktop/pages/home/refund-mb.webp',
        link: PAGE_URLS.PROMOTION
      }
    ]
  }
}
